import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Biogeo from "/src/components/img/biogeo"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Studijní materiály Biogeografie" />
    <h1>Studijní materiály Biogeografie</h1>
    <p>Níže jsou sepsány on-line učební materiály <strong>z předmětu <strong>Z0005 Biogeografie</strong></strong>na brněnské geografii na MUNI z jarního semestru 2020. Zdrojem poznámek jsou především přednášky, elektronické studijní materiály a povinná literatura. Studijní materiály jsou zdarma.</p>
    <p><em>(Kdyby se Vám náhodou chtělo, můžete mě podpořit lajkem na <a href="https://www.facebook.com/zemepisec">Fejsbůku</a>)</em></p>
    <hr />
    <ImgCon><Biogeo /><div><h2>Co je biogeografie?</h2>
    <p><strong>Biogeografie</strong> je věda, která se zabývá studiem rozšíření organismů v prostoru a čase. Dá se říci, že se biogeografie snaží dokumentovat a pochopit prostorové rozložení biologické rozmanitosti a změny tohoto rozložení v čase.</p>
    <p><em>(podrobněji v kapitole <Link to="/biogeografie/definice/">Definice biogeografie</Link>)</em></p></div></ImgCon>
    <hr />
    <h2>Témata studijních materiálů biogeografie</h2>
    <ul>
      <li><Link to="/biogeografie/definice/">Definice biogeografie</Link></li>
      <li><Link to="/biogeografie/historie/">Historie biogeografie</Link></li>
      <li><Link to="/biogeografie/ekologicka/">Ekologická biogeografie</Link>
      <ul>
      <li><Link to="/biogeografie/ekologicka/ekologicke-faktory/">Ekologické faktory, valence a nika</Link></li>
      </ul>
      <ul>
      <li><Link to="/biogeografie/ekologicka/abioticke-faktory/">Abiotické ekologické faktory</Link></li>
      <li><Link to="/biogeografie/ekologicka/bioticke-interakce/">Biotické interakce</Link></li>
      <li><Link to="/biogeografie/ekologicka/biogeograficka-pravidla/">Biogeografická pravidla</Link></li>
      </ul>
      </li>
      <li><Link to="/biogeografie/evolucni/">Evoluční biogeografie</Link>
      <ul>
      <li><Link to="/biogeografie/evolucni/koncepty-druhu/">Koncepty pojetí druhu</Link></li>
      <li><Link to="/biogeografie/evolucni/speciace/">Speciace (vznik nových druhů)</Link></li>
      <li><Link to="/biogeografie/evolucni/makroevoluce/">Makroevoluce</Link></li>
      </ul>
      </li>
      <li><Link to="/biogeografie/disperze/">Disperze druhů</Link>
      <ul>
      <li><Link to="/biogeografie/disperze/ekologicke-sireni/">Ekologické šíření druhů</Link></li>
      <li><Link to="/biogeografie/disperze/biogeograficke-sireni/">Biogeografické šíření druhů</Link></li>
      </ul>
      </li>
      <li><Link to="/biogeografie/areografie/">Areografie</Link>
      <ul>
      <li><Link to="/biogeografie/areografie/makroekologicke-vlastnosti-arealu/">Vlastnosti areálů a makroekologie</Link></li>
      <li><Link to="/biogeografie/areografie/faktory-ovlivnujici-rozsireni-druhu/">Faktory ovlivňující rozšíření druhů</Link></li>
      <li><Link to="/biogeografie/areografie/typy-rozsireni/">Typy rozšíření a areálů</Link></li>
      </ul>
      </li>
      <li><Link to="/biogeografie/spolecenstva/">Společenstva a jejich geografie</Link>
      <ul>
      <li><Link to="/biogeografie/spolecenstva/globalni-biomy/">Biomy (globální společenstva)</Link></li>
      <li><Link to="/biogeografie/spolecenstva/vegetace-evropy/">Společenstva a vegetace Evropy</Link></li>
      </ul>
      </li>
      <li><Link to="/biogeografie/biodiverzita/">Biodiverzita</Link>
      <ul>
      <li><Link to="/biogeografie/biodiverzita/globalni-druhove-bohatstvi/">Globální druhová diverzita</Link></li>
      <li><Link to="/biogeografie/biodiverzita/sar-iar-vztah-diverzity-plochy/">SAR: Vztah diverzity a plochy</Link></li>
      <li><Link to="/biogeografie/biodiverzita/lokalni-druhove-bohatstvi/">Lokální druhová diverzita</Link></li>
      </ul>
      </li>
      <li><Link to="/biogeografie/ostrovni/">Ostrovní biogeografie</Link>
      <ul>
      <li><Link to="/biogeografie/ostrovni/zvlastnosti-bioty/">Zvláštnosti ostrovní bioty</Link></li>
      </ul>
      </li>
      <li><Link to="/biogeografie/invazni-ekologie/">Invazní ekologie</Link>
      <ul>
      <li><Link to="/biogeografie/invazni-ekologie/invaze-zavlecene-druhy-a-dalsi-terminologie/">Invaze, zavlečené druhy a další terminologie</Link></li>
      <li><Link to="/biogeografie/invazni-ekologie/invazni-procesy-ve-svete-a-cr/">Invazní procesy ve světě a ČR</Link></li>
      <li><Link to="/biogeografie/invazni-ekologie/faktory-invazivnosti-a-invazibility/">Faktory invazivnosti a invazibility</Link></li>
      </ul>
      </li>
    </ul>
  </Layout>
)
